<script>

    import typical from 'vue-typical'
    import NavLanding from "../../components/structure/nav-landing";
    import FooterLanding from "../../components/structure/footer-landing";
    export default {
        components: {FooterLanding, NavLanding, typical},
        name:'landingUniversity',
        mounted () {
            window.scrollTo(0, 0)
        }
    };
</script>

<template>
    <div>
        <nav-landing/>
        <div v-scroll-spy>
            <section class=" py-landing sec-img text-center">
                <div class="back-content py-landing">
                <div class="row m-0 justify-content-center">
                    <div class="col-12 col-md-10 col-lg-9">
                        <typical
                                class="typicalWrapper text-white font-weight-bold mb-3 mt-5"
                                :steps="[$t('landing.nav.university')+' - ', 500,$t('landing.university.section1.title'), 1000]"
                                :loop="Infinity"
                                :wrapper="'h1'"
                        ></typical>
                        <p class="font-size-18 my-4 text-muted">
                            {{$t('landing.university.section1.desc')}}
                        </p>

                        <a class="btn btn-fill login-btn w-xs" @click="$router.push({ name: 'Register' })">{{$t('landing.nav.join')}}</a>


                    </div>
                </div>
                </div>
            </section>
            <section class="section pt-4 bg-white" id="why">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 m-auto">
                            <h1 class="text-center my-5">{{$t('landing.university.section2.t1')}}</h1>
                            <p class="font-size-16 mb-2 text-center">{{$t('landing.university.section2.p11')}}</p>
                            <p class="font-size-16 mb-5 text-center">{{$t('landing.university.section2.p12')}}</p>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="card border">
                                        <div class="card-body text-center">
                                            <i class="fas fa-flag fa-3x m-auto" style="color: #6769e8"></i>
                                            <h4 class="my-3">{{$t('landing.university.section2.t2')}}</h4>
                                            <p class="text-muted">{{$t('landing.university.section2.p2')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="card border">
                                        <div class="card-body text-center">
                                            <i class="fas fa-book fa-3x m-auto" style="color: #6769e8"></i>
                                            <h4 class="my-3">{{$t('landing.university.section2.t3')}}</h4>
                                            <p class="text-muted">{{$t('landing.university.section2.p3')}}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>

            <section class="section  pt-4 bg-color-blue-nav" id="faqs">
                <div class="container">

                    <div class="row align-items-center justify-content-center pt-4">
                        <div class="col-xl-4">
                            <div>
                                <img
                                        src="@/assets/images/crisis/crisis-missions.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                />
                            </div>
                        </div>
                        <div class="col-xl-5">
                            <h2 class="text-info mt-4">{{$t('landing.university.section3.t')}}</h2>
                            <p class="text-white my-4 font-size-16">{{$t('landing.university.section3.p')}}</p>
                        </div>
                    </div>
                    <!-- end row -->

                    <div class="row align-items-center justify-content-center">
                        <div class="col-xl-5 pt-xl-5">
                            <h2 class="text-info mt-4">{{$t('landing.university.section4.t')}}</h2>
                            <p class="my-4 font-size-16 text-white">{{$t('landing.university.section4.p')}}</p>
                        </div>
                        <div class="col-xl-4">
                            <div class="mt-4 mr-md-0">
                                <img
                                        src="@/assets/images/crisis/adapted-lab.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col-xl-4">
                            <div>
                                <img
                                        src="@/assets/images/crisis/Exclusive-discounts.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                />
                            </div>
                        </div>
                        <div class="col-xl-5">
                            <h2 class="text-info mt-4">{{$t('landing.university.section5.t')}}</h2>
                            <p class="text-white my-4 font-size-16">{{$t('landing.university.section5.p')}}</p>
                        </div>
                    </div>


                    <!-- end row -->
                </div>
            </section>


            <!-- Footer start -->
           <footer-landing/>
            <!-- Footer end -->
        </div>
    </div>
</template>
<style scoped src="@/assets/styles/custom/structure/landing.css"></style>